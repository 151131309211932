<template>
  <section id="pf-asociar-lotes">

    <b-row>
      <b-col>
        <BrunaForm ref="aditivoRef" :fields="this.aditivo.fields" :card="this.aditivo.card">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewAditivo" size="lg">
              Guardar
            </b-button>
          </template>
        </BrunaForm>


      </b-col>
    </b-row>

    <HistoricoAditivos ref="gridAditivos"></HistoricoAditivos>

  </section>
</template>
<script>
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from '@/router';
import HistoricoAditivos from "./HistoricoAditivos.vue";


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BrunaForm,
    ToastificationContent,
    HistoricoAditivos: HistoricoAditivos,
  },
  data() {
    return {
      loading: false,
      aditivo: {
        card: {
          title: "CARACTERÍSTICAS",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: '',
            body: ""
          }
        },
        fields: [
          {
            label: "CÓDIGO",
            type: "input",
            id: 'codigo',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "DESCRIPCIÓN",
            type: "input",
            id: 'descripcion',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "DIRIGIDO A",
            type: "select",
            id: 'dirigido',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 'Operardor microdosimetria', text: "Operador microdosimetria" },
            ],
            value: null
          },
          {
            label: "EQUIPO",
            type: "select",
            id: 'equipo',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 'Linea WOLFKING', text: "Linea WOLFKING" },
            ],
            value: null
          },
          {
            label: "FORMULA",
            type: "select",
            id: 'formula',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion"},
              { value: "VS", text: "VS"},
            ],
            value: null
          }
        ]
      }
    };
  },
  methods: {

    formatData()
    {
      let aditivo = this.aditivo.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      return { ...aditivo}
    },

    cleanForm(){
      this.aditivo.fields.forEach( e => {
        e.value = null;
      });
    },

    addNewAditivo() {
      console.log(JSON.stringify(this.formatData(), null, 2))
      Promise.all([
        this.$refs.aditivoRef.validate(),
      ]).then(([aditivosValid]) => {
        if (aditivosValid) {

          useApiServices.postAditivosCreate(this.formatData(), this)
            .then((response) => {
              console.log(response);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              this.$refs.gridAditivos.refreshRows()

            })
            .catch((error) => {

              console.log(error)

              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });


    }
  },
  mounted() {

  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3 {
  border: 1px solid green !important;
  background-color: green !important;
  color: white !important;
}

.mt-3:hover {

  transition: 0.3s ease-in !important;
  border: 1px solid green !important;
  background-color: transparent !important;
  color: green !important;
  color: white;
}

.mt-2 {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.mt-2:hover {

  transition: 0.5s ease-in !important;
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
</style>