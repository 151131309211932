<template>
  <section id="pf-historico-aditivos">
    <b-row>
      <b-col sm="12">
        <ag-grid-table ref="gridListAditivos" :configUrl="config" :dataUrl="data" :editUrl="edit" rowSelection="single">
        </ag-grid-table>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";
import RepeatStatistics from "@/views/clients/pf/components/RepeatStatistics";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import axios from "@axios";


export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BrunaForm,
    ToastificationContent,
    EliminarActions: EliminarActions,
    RepeatStatistics,
    NumberCellEditor,

  },
  data() {
    return {
      config: useApiServices.historicoAditivosConfig,
      data: useApiServices.historicoAditivosData,
      edit: useApiServices.historicoAditivosEdit,
      itemsData: [],
    };
  },
  methods: {

    selectionLotesBrunaTable(rows) {
      this.formatData.selectBrunaTable = rows[0].meta_data.codigo;
      if (this.formatData.selectBrunaTable != null) {
        this.formatData.selected = true
      };

    },
    refreshRows() {
      this.$refs.gridListAditivos.refreshRows()
    }


  },
  mounted() {

  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3 {
  border: 1px solid green !important;
  background-color: green !important;
  color: white !important;
}

.mt-3:hover {

  transition: 0.3s ease-in !important;
  border: 1px solid green !important;
  background-color: transparent !important;
  color: green !important;
  color: white;
}

.mt-2 {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.mt-2:hover {

  transition: 0.5s ease-in !important;
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
</style>