<template>
  <div>
    <b-button-group size="sm">
      <!-- <b-button @click="$bvModal.show('bv-modal-example')" variant="outline-primary"> -->
      <b-button variant="outline-primary" @click="deleteMmpp()">
        <span class="align-middle">Eliminar</span>
      </b-button>
    </b-button-group>
    <b-modal ref="confirmacion-modal" hide-footer>
      <div class="d-block text-center">
        <h3>Estás seguro que desea eliminar la Materia prima?</h3>
      </div>
      <div class="foot">
        <b-button class="btn-modal mt-3" variant="outline-warning" @click="toggleModal">Confirmar</b-button>
        <b-button class="btn-modal mt-3" variant="outline-danger" @click="hideModal">Cancelar</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";
import useApiServices from '@/services/useApiServices.js';


export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
  },

  directives: {
  },

  data: function () {
    return {
      cellValue: null,
    };
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);

  },
  methods: {
    showModal() {
      this.$refs['confirmacion-modal'].show()
    },
    hideModal() {
      this.$refs['confirmacion-modal'].hide()
    },
    toggleModal() {
      console.log(this.cellValue.data.id);
      useApiServices.deleteAditivo(this.cellValue.data.id).then((response) => {
        console.log(response);
        setTimeout(() => {
                window.location.reload(true);
              }, 1000);
      })
        .catch((error) => {
          console.log(error);
        });
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['confirmacion-modal'].toggle('#toggle-btn')
    },
    deleteMmpp() {
      this.showModal();
      // console.log(this.cellValue.data.id);      
    },
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    getValueToDisplay(params) {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
.foot{
  justify-content: flex-end;
  text-align: end;
  .btn-modal {
    margin: 0px 5px;
  }
}
</style>
